<template>
  <div class="page2">
    <div class="header">
      <div class="header-l">
        <span>专项行动关键字：</span>
        <el-input prefix-icon="el-icon-search" class="input" v-model="specialActionsName" placeholder="请输入事件名称" clearable></el-input>
        <div class="btn m-l-24" @click="searchSpecialActions">查询</div>
        <div class="reset" @click="remakeSpecialActions">重置</div>
      </div>
      <div class="header-r btn" @click="goAdd">
        <img src="@/assets/img/gjh/add.png" alt="" srcset="" />
        新增专项行动
      </div>
    </div>
    <div class="more" @click="gomore">更多话题 ({{ total }})</div>
    <div class="box">
      <el-skeleton :rows="18" animated :loading="specialActionsLoading" />
      <!-- <el-carousel indicator-position="outside" height="700px" arrow="always" :interval="3000000000" :autoplay="false"
                   v-show="divisionSpecialActionsData && divisionSpecialActionsData.length > 0"> -->
        <div v-for="(items, indexs) in divisionSpecialActionsData" :key="indexs">
          <div class="boxcontent-center-div">
            <div class="center-div" v-for="(item, index) in items" :key="index">
              <div>
                <div class="handel" @click="goDetail(item)">
                  <div class="center-div-title1">
                    <img src="@/assets/img/lvchaofeng/set/publicc.png" alt="未知" style="margin-right: 6px" v-if="item.isPublicPlan == 1" />
                    <span class="titleText">{{ item.name }}</span>
                  </div>
                  <div class="center-div-time">
                    {{ item.startTime + " - " + item.endTime }}
                  </div>
                  <div class="center-div-bac">
                    <div class="center-div-bac-left">
                      <div class="center-div-bac-left-left">
                        信息总量
                        <span>{{ item.statusName }}</span>
                      </div>
                      <div class="center-div-bac-left-right">
                        {{ item.data.count }}
                      </div>
                    </div>
                  </div>
                  <!-- 饼图 -->
                  <div v-show="item.data && item.data.data.length > 0">
                    <!-- <div class="messagePar" :id="'sg' + indexs + index"></div> -->
                    <div style="
                        font-size: 14px;
                        font-weight: 500;
                        padding: 0 24px;
                        color: #999999;
                        margin: 16px 0;
                      ">
                      信息分布
                    </div>
                    <el-carousel :initial-index="1" arrow="never" trigger="click" height="150px" v-if="item.data.data && item.data.data.length > 0">
                      <el-carousel-item v-for="(x, y) in item.data.data" :key="y">
                        <ul class="center-div-tab" v-if="item.data.data && item.data.data.length > 0">
                          <li v-for="(va, inx) in x" :key="inx">
                            <span>{{ va.keyText }}</span>
                            <span>{{ va.cnt }}</span>
                          </li>
                        </ul>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                  <div class="analyse-container" v-if="!item.data || item.data.data.length == 0">
                    <div class="analyse-container-img">
                      <img src="@/assets/img/gjh/weitu.png" />
                      <p>暂无数据</p>
                    </div>
                  </div>
                </div>
              </div>
               <!-- v-if="item.isPublicPlan == 0" -->
              <div class="center-div-bottom" v-if="item.isPublicPlan == 0">
                <!-- <el-button style="color: #02bc7c; border-left: none;width: 34%;border-bottom: none;border-right: none;border-radius: 0px 0px 0px 10px;"
                           @click="deepUpdate(item.id)" :disabled="item.status == 2">
                  <img src="@/assets/img/gjh/shuxin.png" />
                  深度更新
                </el-button> -->
                <div class="bianji-div" @click="editSpecialActions(item)">
                  <img src="@/assets/img/gjh/bianjilv.png" />
                  编辑
                </div>
                <div style="color: #ea3342" @click="closeSpecialActions(item.id)">
                  <img src="@/assets/img/gjh/guanbi.png" style="position: relative; top: 1px" />
                  关闭话题
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- </el-carousel> -->
      <Bitmap v-if="!specialActionsLoading && divisionSpecialActionsData.length == 0" />
    </div>
  </div>
</template>

<script>
import Special from "@/api/special/index";
import Error from "@/api/error/index";
import Bitmap from "@/components/bitmap.vue";

export default {
  components: {
    Bitmap,
  },
  data() {
    return {
      // 加载效果
      specialActionsLoading: false,
      // 分割列表数据
      divisionSpecialActionsData: [],
      // 搜索数据名字
      specialActionsName: "",
      // 分页
      pageData: {
        page: 1,
        limit: 10,
      },
      total: 0,
      // 详情主体类型
      organizationTypeList: [],
    };
  },
  created() {
    this.getSubjectList();
    this.getSpecialActionsList();
    this.getCloseSpecialActionsList();
  },
  mounted() { },
  methods: {
    // 深度更新
    async deepUpdate(data) {
      const res = await Special.deepUpdate(data);
      if (res.data.code == 200) {
        this.$message.success("深度更新成功！");
        this.getSpecialActionsList();
      }
    },
    // 编辑专项行动
    editSpecialActions(item) {
      this.$router.push({
        path: "/addspecial",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              item: item,
              title: "编辑专项行动",
              organizationTypeList: this.organizationTypeList,
            })
          ),
        },
      });
    },
    // 获取主体类型
    async getSubjectList() {
      const res = await Error.getSubjectList();
      if (res && res.data && res.data.code == 200) {
        this.organizationTypeList = res.data.data;
      }
    },
    // 关闭专项行动
    async closeSpecialActions(data) {
      const res = await Special.closeSpecialActions(data);
      if (res.data.code == 200) {
        this.$message.success("关闭专项行动成功！");
        this.getSpecialActionsList();
        this.getCloseSpecialActionsList();
      }
    },
    // 重置专项行动
    remakeSpecialActions() {
      this.specialActionsName = "";
      this.divisionSpecialActionsData = [];
      this.getSpecialActionsList();
    },
    // 查询专项行动
    searchSpecialActions() {
      this.divisionSpecialActionsData = [];
      this.getSpecialActionsList();
    },
    // 获取专项行动列表
    async getSpecialActionsList() {
      this.divisionSpecialActionsData = [];
      this.specialActionsLoading = true;
      let data = {};
      if (this.specialActionsName != "") {
        data.name = this.specialActionsName;
      }
      const res = await Special.getSpecialActionsList(data);
      if (res && res.data && res.data.code == 200) {
        if (res.data.data.length > 0) {
          for (let i = 0; i < res.data.data.length; i += 4) {
            this.divisionSpecialActionsData.push(res.data.data.slice(i, i + 4));
          }
          this.divisionSpecialActionsData.forEach((z, i) => {
            z.forEach((x, j) => {
              if (!x.endTime) {
                x.endTime = "至今";
              }
              let EchartsArr = [];
              if (x.data) {
                let dataArr = [];
                for (let i = 0; i < x.data.data.length; i += 8) {
                  dataArr.push(x.data.data.slice(i, i + 8));
                }
                if (dataArr.length == 2) {
                  dataArr.push(dataArr[0]);
                  dataArr.unshift(dataArr[1]);
                }
                if (dataArr.length == 3) {
                  dataArr.push(dataArr[0]);
                }
                x.data.data = dataArr;
                let EchartsObj = {
                  name: "",
                  value: "",
                };
                x.data.data.forEach((y) => {
                  y.forEach((m) => {
                    if (!m.keyText) {
                      m.keyText = "测试";
                    }
                    EchartsObj = {
                      name: m.keyText,
                      value: m.cnt,
                    };
                    if (EchartsObj.value > 0) {
                      EchartsArr.push(EchartsObj);
                    }
                  });
                });
              } else {
                x.data = {
                  count: "0",
                  data: [],
                  todayCount: "0",
                };
              }
              // setTimeout(() => {
              //   this.setecharts(i, j, EchartsArr);
              // }, 100);
            });
            this.specialActionsLoading = false;
          });
        } else {
          this.divisionSpecialActionsData = [];
          this.specialActionsLoading = false;
        }
      } else {
        this.divisionSpecialActionsData = [];
        this.specialActionsLoading = false;
      }
    },
    // echarts数据去重
    echartsNoRepeat(arr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a.name) && res.set(a.name, 1));
    },
    // 获取已关闭行动列表
    async getCloseSpecialActionsList() {
      const res = await Special.getCloseSpecialActionsList(this.pageData);
      if (res && res.data && res.data.code == 200) {
        this.total = res.data.data.totalCount;
      }
    },
    // 更多话题
    gomore() {
      this.$router.push({ path: "/moretopics" });
    },
    // 进入详情页
    goDetail(item) {
      let routeData = this.$router.resolve({
        path: "/topicListDetails",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: item.id,
              title: item.name,
              item: item,
            })
          ),
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 新增or修改专项行动页面
    goAdd() {
      this.$router.push({ path: "/addspecial" });
    },
    // setecharts(i, j, EchartsArr) {
    //   EchartsArr = this.echartsNoRepeat(EchartsArr);
    //   const myChart = this.$echarts.init(document.getElementById("sg" + i + j));
    //   let option = {
    //     color: [
    //       "#009CFC",
    //       "#11DAE9",
    //       "#21CA63",
    //       "#FFC75C",
    //       "#FF855B",
    //       "#FE6299",
    //       "#802EEC",
    //       "#2E59EC",
    //     ],
    //     label: {
    //       formatter: "{b}",
    //     },
    //     tooltip: {},
    //     series: [
    //       {
    //         type: "pie",
    //         center: ["50%", "50%"],
    //         radius: 70,
    //         minAngle: 10,
    //         itemStyle: {
    //           borderWidth: 1,
    //           borderColor: "#fff",
    //         },
    //         data: EchartsArr,
    //       },
    //     ],
    //   };
    //   myChart.setOption(option);
    // },
  },
};
</script>

<style lang="scss" scoped>
.analyse-container-img {
  text-align: center;
  // margin-top: 40px;
  img {
    width: 60%;
    height: 60%;
    margin-top: 20px;
  }
  p {
    color: #999999;
    font-size: 14px;
  }
}
.page2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 24px 0 24px;
  // background: url("../../assets/img/login/saBG.png") no-repeat;
}
.header {
  width: 100%;
  height: auto;
  // padding: 40px 0 0 0;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #333333;
  .header-l {
    display: flex;
    align-items: center;
    position: relative;
    .input {
      width: 280px;
    }
    .reset {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 36px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #e1e1e1;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-left: 16px;
      cursor: pointer;
    }
  }
  .header-r {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
// 更多信息
.more {
  margin-top: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #f4630b;
  cursor: pointer;
}
// 主体
.box {
  margin-top: 40px;
  .boxcontent-center-div {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 0px 60px;

    .center-div {
      width: 345px;
      height: 455px; // 740px
      background: #ffffff;
      box-shadow: 0px 1px 12px -3px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      margin-right: 30px;
      margin-bottom: 20px;
      padding: 16px 0;
      position: relative;
      .messagePar {
        width: 100%;
        height: 214px;
        margin-top: 10px;
      }

      .center-div-title {
        margin: 0 24px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 16px;

        .center-div-title-left {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }

        .center-div-title-right {
          font-size: 14px;
          color: #02bc7c;
        }

        .center-div-title-right-update {
          color: #2e59ec;
          font-size: 14px;
        }

        .center-div-title-right-stop {
          font-size: 14px;
          color: #ff9000;
        }
      }

      .center-div-title1 {
        padding: 0 24px;
        margin: 16px 0;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
        cursor: pointer;
        width: 300px;
        display: flex;
        align-items: center;
        .titleText {
          width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .center-div-time {
        margin: 0 24px;
        font-size: 14px;
        color: #999999;
        line-height: 20px;
      }

      .center-div-bac {
        margin: 0 24px;
        display: flex;
        // align-items: center;
        // padding: 16px 24px;

        width: 302px;
        height: 93px;
        border-radius: 4px;
        background-image: url("../../assets/img/gjh/cardBG.png");
        background-repeat: no-repeat;
        background-size: 100%;
        margin-top: 16px;

        .center-div-bac-left {
          width: 100%;
          // display: flex;
          // flex-direction: column;
          // justify-content: space-between;
          padding: 12px 24px;
          .center-div-bac-left-left {
            position: relative;
            font-size: 14px;
            color: #ffffff;
            // line-height: 20px;
            span {
              position: absolute;
              right: 0;
            }
          }

          .center-div-bac-left-right {
            margin-top: 8px;
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            line-height: 28px;
          }
        }
      }

      .center-div-tab {
        margin: 0 24px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 14px;
        li {
          width: 49%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 30px;
          background: #f9fafc;
          border-radius: 4px;
          padding: 0 16px;
          margin-bottom: 8px;
        }
      }

      .center-div-tab1 {
        margin: 0 24px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          width: 120px;
          height: 48px;
          background: #f9fafc;
          border-radius: 4px;
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          padding: 8px;

          img {
            width: 32px;
            height: 32px;
            margin-right: 8px;
          }

          .center-div-tab1-right {
            font-size: 10px;
            color: #999999;

            div {
              font-size: 14px;
              color: #333333;
              width: 55px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .center-div-bottom {
        display: flex;
        position: absolute;
        bottom: 0;
        width: 100%;
        ::v-deep .el-button:hover {
          border-color: transparent;
          background-color: transparent;
          border-color: #e4e6ec;
        }
        ::v-deep .el-button {
          border-color: transparent;
          background-color: transparent;
          border-color: #e4e6ec;
        }
        .bianji-div{
          border-radius: 0 0 0 8px;
          border-left: none !important;
        }
        div {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 14px 0;
          border-top: 1px solid #e4e6ec;
          border-left: 1px solid #e4e6ec;
          font-size: 14px;
          color: #333333;
          cursor: pointer;

          img {
            margin-right: 4px;
          }
        }
      }
    }
  }
}
</style>
